import React from "react";
import { graphql } from "gatsby";
import Builder from "../components/global/builder";

const Page = ({
  data: {
    wpPage: {
      title,
      page,
      sections: { sections },
    },
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext,
  location,
}) => {
  return (
    <Builder
      title={title}
      page={page}
      sections={sections}
      themeSettings={themeSettings}
      pageContext={pageContext}
      location={location}
    />
  );
};

export const pageQuery = graphql`
  query Page($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        pageSchema
      }
      sections {
        sections {
          ... on WpPage_Sections_Sections_Slider {
            ...Slider
          }
          ... on WpPage_Sections_Sections_HiThereIntro {
            ...HiThereIntro
          }
          ... on WpPage_Sections_Sections_ServicesIntro {
            ...ServicesIntro
          }
          ... on WpPage_Sections_Sections_ServicesIntroContent {
            ...ServicesIntroContent
          }
          ... on WpPage_Sections_Sections_ServicesIntroServices {
            ...ServicesIntroServices
          }
          ... on WpPage_Sections_Sections_OurWorkIntro {
            ...OurWorkIntro
          }
          ... on WpPage_Sections_Sections_TestimonialsIntro {
            ...TestimonialsIntro
          }
          ... on WpPage_Sections_Sections_BrandsIntro {
            ...BrandsIntro
          }
          ... on WpPage_Sections_Sections_Banner {
            ...Banner
          }
          ... on WpPage_Sections_Sections_ContentWithButton {
            ...ContentWithButton
          }
          ... on WpPage_Sections_Sections_PortfolioImage {
            ...PortfolioImage
          }
          ... on WpPage_Sections_Sections_SingleQuote {
            ...SingleQuote
          }
          ... on WpPage_Sections_Sections_IntroContent {
            ...IntroContent
          }
          ... on WpPage_Sections_Sections_ServiceContent {
            ...ServiceContent
          }
          ... on WpPage_Sections_Sections_RelatedServices {
            ...RelatedServices
          }
          ... on WpPage_Sections_Sections_CenteredContent {
            ...CenteredContent
          }
          ... on WpPage_Sections_Sections_SimpleServiceContent {
            ...SimpleServiceContent
          }
          ... on WpPage_Sections_Sections_History {
            ...History
          }
          ... on WpPage_Sections_Sections_ImageWithContent {
            ...ImageWithContent
          }
          ... on WpPage_Sections_Sections_Steps {
            ...Steps
          }
          ... on WpPage_Sections_Sections_SimpleBanner {
            ...SimpleBanner
          }
          ... on WpPage_Sections_Sections_PayOnline {
            ...PayOnline
          }
          ... on WpPage_Sections_Sections_Contact {
            ...Contact
          }
          ... on WpPage_Sections_Sections_Map {
            ...Map
          }
          ... on WpPage_Sections_Sections_ColumnsIntroWithButton {
            ...ColumnsIntroWithButton
          }
          ... on WpPage_Sections_Sections_TeamIntro {
            ...TeamIntro
          }
          ... on WpPage_Sections_Sections_Team {
            ...Team
          }
          ... on WpPage_Sections_Sections_FullWidthContent {
            ...FullWidthContent
          }
          ... on WpPage_Sections_Sections_LetsTalk {
            ...LetsTalk
          }
          ... on WpPage_Sections_Sections_ProjectGrid {
            ...ProjectGrid
          }
          ... on WpPage_Sections_Sections_Test {
            ...Test
          }
          ... on WpPage_Sections_Sections_ReusableSections {
            ...ReusableSections
          }
          ... on WpPage_Sections_Sections_ValuesAccordion {
            ...ValuesAccordion
          }
          ... on WpPage_Sections_Sections_PumpkinVoting {
            ...PumpkinVoting
          }
          ... on WpPage_Sections_Sections_TermlySection {
            ...TermlySection
          }
          ... on WpPage_Sections_Sections_ContactOnboarding {
            ...ContactOnboarding
          }
          ... on WpPage_Sections_Sections_ContestPoll {
            ...ContestPoll
          }
          ... on WpPage_Sections_Sections_JotformContact {
            ...JotformContact
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          customCssClasses
        }
      }
    }
  }
`;

export default Page;
